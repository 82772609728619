import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

function VisitePage() {
  return (
    <Layout>
      <SEO title="Réalisations 3D Matterport" />
      <div className="mx-auto max-w-screen-xl">
        <div className=" pt-10">
          <h1>Nos réalisations avec 3D Matterport</h1>
          <div className="text-center max-w-xl mx-auto"></div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Maison en partenariat avec Hello-Immo
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=ob96EvxkXKs"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Appartement en partenariat avec le Cabinet Rivet-Vigreux
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=oNRZK8eSuTQ"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Maison à Toulouse en partenariat avec Dupuy Immobilier
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=cduvTidcE23"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Appartement en partenariat avec Hello-Immo
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=vtN1UTPkZBq"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Maison en partenariat avec Hello-Immo
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=BL57cuWq2bN"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Appartement en partenariat avec LB Immo
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=DcpdDa9iJVw"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Appartement aux Carmes à Toulouse
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=zv7PvsxDc7X"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Appartement T2 à Launaguet
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=2wEGbwawFiw"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Restaurant le Your&apos;s à Toulouse
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=wvCrcRwDCKe"
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className="text-center mt-10">
          <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">
            Restaurant le New School à Toulouse
          </h2>
          <div className="iframe-container">
            <iframe
              src="https://my.matterport.com/show/?m=Qg4czR6qAxk"
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <p className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
              Vous voulez mettre en valeur votre bien ou votre activité ?
              Contactez nous!
            </p>
            <p className="text-xl leading-9 font-extrabold tracking-tight text-gray-500">
              La technologie Matterport 3D est un outil puissant pour booster
              votre activité.
            </p>
            <div className="mt-8 flex justify-center flex-wrap">
              <div className="inline-flex rounded-md shadow mb-1">
                <Link
                  to="/contact"
                  title="Contactez-nous"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6 pr-1 inline-block"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>{" "}
                  Contactez-nous
                </Link>
              </div>
              <div className="sm:ml-3 inline-flex mb-1">
                <a
                  href="tel:0033582951635"
                  title="Appelez nous"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-700 bg-red-100 hover:text-red-600 hover:bg-red-50 focus:outline-none focus:shadow-outline focus:border-red-300 transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6 pr-1 inline-block"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  05 82 95 16 35
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default VisitePage;
